import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLanguageIdentifier } from "hooks/Forms/useLanguageIdentifier";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import { Link, navigate } from "gatsby";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import CleanButton from "assets/cleanBtn.svg";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import { direction } from "utils/direction";
import { trySampleText } from "utils/sampletext-generator";
import { WordCounterToolFaqData } from "components/FAQ/config";
import ToolFaq from "components/QualityTools/ToolFaq";
import Layout from "components/layout";
import SEO from "components/seo";
import LoadingImage from "assets/LOADING.png";
import useAutoFocus from "hooks/useAutoFocus";

const Index = () => {
  const { status, mutateAsync } = useLanguageIdentifier();
  const [inputLang, setInputLang] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const debouncedInputText = useDebounce(inputText, 3000);
    const [urlText, setUrlText] = useState("");
    const [isEnterText, setIsEnterText] = useState(true);

  useAutoFocus("textarea");
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3 && !inputLang) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText, inputLang]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const {
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const onSubmit = async (data) => {
      setError("");
      // if (inputText.trim() === "") {
      //   alert("Please enter text in the textarea box");
      //   return false;
      // }

      setLoading(true);
       let payload;
        if (urlText){
          payload = {
            tool_name: "word_count",
            user_url: urlText,
          };
        } else {
          payload = {
            tool_name: "word_count",
            user_text: inputText,
            // source_language_code: inputLang,
            ...formCommonPostData(),
          };
        }

      try {
        const response = await mutateAsync(payload);
        setLoading(false);
        const newShareId = response?.meta?.share_id;
        if (newShareId === undefined && !response.success) {
          setError("Unable to generate a response. Please try again.");
          return;
        }
        navigate(`/tools/word-counter/result?share-id=${newShareId}`);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (inputLang && inputText.trim() || urlText) {
      onSubmit();
    }
  }, [inputText, inputLang,urlText]);

  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
  };
  useEffect(() => {
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
  }, [inputText]);

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setInputText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const clearText = () => {
    setWordCount(0);
    setInputText("");
    reset({ message: "" });
    setInputLang("");
    setError("");
  };
  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setInputText("");
  };
  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
  };

  const clearLink = () => {
    setUrlText("");
  };

  return (
    <Layout>
      <SEO
        title="Free AI Word Counter"
        description="Effortlessly analyze multilingual text with the Tomedes Word Count Tool. Detect languages, count words and characters, and receive precise results tailored for each language."
        slug="/tools/word-counter"
      />
      <div className="bg-[#F5F5F5] ">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="py-12 lg:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
          <div>
            <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
              Word Counter
            </h1>
            <h2 className="text-[#0A2641] text-center md:text-start font-bold text-[28px] lg:text-[48px] leading-[48px] lg:leading-[56px] font-primary">
              Stay Within Character Limits with Precision and Ease
            </h2>
            <p className="hidden md:block text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
              The Tomedes Word and Character Count Tool makes managing
              multilingual text effortless. Whether you're crafting content for
              social media, preparing documents for translation, or ensuring
              text fits design constraints, this tool provides precise word and
              character counts tailored to each language's unique rules. Save
              time, maintain accuracy, and streamline your content workflow with
              ease.
            </p>
          </div>
          <div>
          <div className="flex gap-3 bg-white pt-2 pl-5 rounded-t-lg">
            <button
              onClick={() => toggleInput(true)}
              className={`py-1 px-3 rounded-md font-semibold ${
                isEnterText
                  ? "text-white bg-[#7AA7FF]"
                  : "text-[#7AA7FF] bg-[#F0F5FF]"
              }`}
            >
              Enter Text
            </button>
            <button
              onClick={() => toggleInput(false)}
              className={`py-1 px-3 rounded-md font-semibold ${
                isEnterText
                  ? "text-[#7AA7FF] bg-[#F0F5FF]"
                  : "text-white bg-[#7AA7FF]"
              }`}
            >
              Enter URL
            </button>
          </div>
          {isEnterText ? (
            <form
              action=""
              className="relative shadow-lg rounded-b-lg bg-white"
              // onSubmit={handleSubmit(onSubmit)}
            >
              {fileUploadLoading ? (
                <FileLoadingInput file={file} />
              ) : sizeLimitError && !fileUploadLoading ? (
                <ReUploadFile
                  file={file}
                  setFile={setFile}
                  setSizeLImitError={setSizeLImitError}
                  uploadFile={uploadFile}
                />
              ) : uploadError && !fileUploadLoading ? (
                <UnsupportedFile
                  file={file}
                  setSizeLImitError={setSizeLImitError}
                  setFile={setFile}
                  uploadFile={uploadFile}
                  setFileUploadError={setUploadError}
                />
              ) : (
                <div className="relative">
                  <textarea
                    autoFocus
                    dir={direction(inputLang)}
                    placeholder={textLoader ? "" : "Type your text here or"}
                    name="message"
                    id="message"
                    {...register("message")}
                    className={`anonymize_tool  ${
                      direction(inputLang) === "rtl" ? "pl-8 pr-5" : "pl-5 pr-8"
                    } py-5  min-h-[360px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
                    onChange={handleTextChange}
                    value={inputText}
                    dangerouslySetInnerHTML={{
                      __html: inputText.replace(/\n/g, "<br />"),
                    }}
                  />
                  {textLoader && (
                    <div className="absolute top-[17px] left-[14px] text-[#B4B4B4] italic">
                      Adding sample text...
                    </div>
                  )}
                  {!inputText && !textLoader && (
                    <button
                      className="absolute top-[17px] left-[182px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                      onClick={() => trySampleText(setInputText, setTextLoader)}
                    >
                      Try Sample Text
                    </button>
                  )}
                </div>
              )}

              {wordCount > 0 && (
                <p
                  className={`absolute top-[14px]  ${
                    direction(inputLang) === "rtl"
                      ? "left-[10px]"
                      : "right-[16px]"
                  }  w-max cursor-pointer`}
                  onClick={clearText}
                >
                  <img src={CleanButton} alt="clean-button" />
                </p>
              )}

              <div className="flex justify-between items-center p-4">
                {inputText ? (
                  <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                    word count: {wordCount}
                  </p>
                ) : (
                  <>
                    <label
                      htmlFor="uploadFile"
                      className="flex gap-2 cursor-pointer"
                    >
                      <img src={UploadIcon} alt="upload_icon" />
                      <span>Upload File</span>
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id="uploadFile"
                      onChange={uploadFile}
                    />
                  </>
                )}
              </div>
            </form>
          ) : (
            <div className="bg-white shadow-lg rounded-b-lg">
                          <textarea
                            name="url"
                            value={urlText}
                            placeholder="Paste URL here"
                            className={`min-h-[240px] px-5  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3  bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                            onChange={handleLinkChange}
                            readOnly={loading}
                          />
                          <div className="flex justify-between items-center p-4 mt-8">
                            <span></span>
                            <div className="flex gap-2">
                              <button
                                type="button"
                                className={` ${
                                  urlText.length > 0
                                    ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                                    : "border border-[#B0B0B0] text-[#B0B0B0]"
                                } flex gap-2 bg-white px-4 py-2 rounded-md`}
                                onClick={clearLink}
                                disabled={urlText.length === 0}
                              >
                                Clear link
                              </button>
                              {/* <button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                disabled={!urlText}
                                className={`bg-[#5B93FF] ${
                                  !urlText ? " cursor-not-allowed" : ""
                                } text-white px-4 py-2 rounded-md`}
                              >
                                {loading ? <Loader /> : "Identify topi"}
                              </button> */}
                            </div>
                          </div>
                        </div>
          )}
            {loading && (
              <div className="bg-white mt-3 flex flex-col items-center justify-center gap-3 rounded-lg py-10">
                <img
                  src={LoadingImage}
                  alt="loading"
                  className="animate-spin"
                />
                <p className="text-center text-base font-medium font-opensans  text-[#858585]">
                  Loading your results...
                </p>
              </div>
            )}

            <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
              Assisted by AI tools. Inaccuracies may occur.
            </p>
          </div>
          <p className="block md:hidden text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
            The Tomedes Word and Character Count Tool makes managing
            multilingual text effortless. Whether you're crafting content for
            social media, preparing documents for translation, or ensuring text
            fits design constraints, this tool provides precise word and
            character counts tailored to each language's unique rules. Save
            time, maintain accuracy, and streamline your content workflow with
            ease.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={WordCounterToolFaqData} />
    </Layout>
  );
};

export default Index;


